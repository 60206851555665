// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { saveAs } from 'file-saver';
import { getToken } from 'firebase/app-check';
import { ADDITIONAL_FIELDS_MAP } from '@ekhodealer/ekho-common/components';
import { OEM_KEY_TO_PROJECT_MAP, PAYMENT_TYPES } from '@ekhodealer/ekho-common';
import { encryptData, dateFormatData } from '@ekhodealer/ekho-common/utils';
import { appCheck, auth } from './firebase';
import { DEMO_DOCUMENT_SIGNING_LINK_MAP, ENV, VEHICLE_STAGE } from './common/data/constants';

// helper function for setting function host
export function setFunctionHost() {
	switch (process.env.REACT_APP_CURR_ENV) {
		case ENV.DEVELOPMENT:
			return 'http://localhost:5000/airdealer-67669/us-central1';
		// return 'https://us-central1-airdealer-67669.cloudfunctions.net';
		case ENV.STAGING:
			return 'https://us-central1-ed-staging.cloudfunctions.net';
		case ENV.PRODUCTION:
			return 'https://us-central1-ad-backend-prod.cloudfunctions.net';
		default:
			return 'http://localhost:5000/airdealer-67669/us-central1';
	}
}

// Helper function for getting the AppCheck token
async function getAppCheckToken() {
	try {
		const appCheckToken = await getToken(appCheck, false);
		return appCheckToken;
	} catch (error) {
		throw new Error('Failed to get AppCheck token: ', error);
	}
}

const FUNCTION_HOST = setFunctionHost();

// function that returns the correct headers for a request
function getRequestHeaders(appCheckToken, authToken) {
	// Add app check token to request headers
	const functionHeaders = { 'X-Firebase-AppCheck': appCheckToken.token };

	if (authToken) {
		functionHeaders.Authorization = `Bearer ${authToken}`;
	}
	return functionHeaders;
}

export const fetchAllPageNames = async () => {
	try {
		const token = await auth.currentUser.getIdToken();

		const url = `${FUNCTION_HOST}/buyer_progress_onfetch_v2/allPageNames`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching authenticated oem data: ${e}`);
	}
};

export async function exchangeToken(token) {
	try {
		const url = `${FUNCTION_HOST}/buyer_onfetch_v2/exchangeToken`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(
			url,
			{ idToken: token, oemKey: process.env.REACT_APP_OEM_KEY },
			{ headers: getRequestHeaders(appCheckToken) },
		);
		return response.data;
	} catch (e) {
		throw new Error(`Error exchanging token: ${e}`);
	}
}

export const getAuthenticatedOemData = async (oemId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/oem_onfetch_v2/authenticated/${oemId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching authenticated oem data: ${e}`);
	}
};

export const submitSurveyData = async (surveyData, purchasedVehicleId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/survey`;
		const appCheckToken = await getAppCheckToken();
		const body = { surveyData, oemKey: process.env.REACT_APP_OEM_KEY };
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error submitting survey data: ${e}`);
	}
};

export const getUnauthenticatedOemData = async (oemId) => {
	try {
		const appCheckToken = await getAppCheckToken();
		try {
			const url = `${FUNCTION_HOST}/oem_onfetch_v2/${oemId}`;
			const response = await axios.get(url, {
				headers: getRequestHeaders(appCheckToken),
				params: { oemKey: process.env.REACT_APP_OEM_KEY },
			});
			return response.data;
		} catch (e) {
			const urlV1 = `${FUNCTION_HOST}/oem_onfetch_v2/${oemId}`;
			const fallbackResponse = await axios.get(urlV1, {
				headers: getRequestHeaders(appCheckToken),
				params: { oemKey: process.env.REACT_APP_OEM_KEY },
			});
			return fallbackResponse.data;
		}
	} catch (e) {
		throw new Error(`Error fetching unauthenticated oem data: ${e}`);
	}
};

export const getPurchasedVehiclesAndCurrentPages = async (currentUser) => {
	try {
		const token = await currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_onfetch_v2/vehiclesAndCurrentPages`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching purchased vehicles: ${e}`);
	}
};

export const getAdditionalDues = async (additionalDuesId, purchasedVehicleId, placedOrderId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_onfetch_v2/additionalDues/${additionalDuesId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				purchasedVehicleId,
				placedOrderId,
			},
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching additional dues: ${e}`);
	}
};

export const saveRegistrationState = async (placedOrderId, registrationState) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2/${placedOrderId}/registrationState`;
		const body = { registrationState, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving registration state: ${e}`);
	}
};

export const saveRegistrationAddress = async (placedOrderId, registrationAddress) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2/${placedOrderId}/registrationAddress`;
		const body = { registrationAddress, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving registration address: ${e}`);
	}
};

export const saveShippingAddress = async (purchasedVehicleId, shippingAddress) => {
	try {
		const deliveryData = {
			...shippingAddress,
		};
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/deliveryLocation`;
		const body = { deliveryData, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving shipping address: ${e}`);
	}
};

export const getBoltOnsMetadata = async (currentUser, placedOrderId, boltOnIds) => {
	try {
		const token = await currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_onfetch_v2/boltOnsMetadata/${placedOrderId}`;
		const body = { oemKey: process.env.REACT_APP_OEM_KEY, boltOnIds };
		const appCheckToken = await getAppCheckToken();

		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching boltOnsMetadata: ${e}`);
	}
};

export const getBuyerData = async () => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_onfetch_v2`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching buyer: ${e}`);
	}
};

export const saveRegistrationDetails = async (vehiclePurchasedId, registrationDetails) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/registrationDetails`;
		const dateFormattedRegistrationDetails = dateFormatData(registrationDetails);
		const encryptedRegistrationDetails = await encryptData(
			dateFormattedRegistrationDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		const body = {
			registrationDetails: encryptedRegistrationDetails,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving registration data: ${e}`);
	}
};

export const saveSelfRegistrationDetails = async (vehiclePurchasedId, selfRegistratinDetails) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/selfRegistrationDetails`;
		const dateFormattedRegDetails = dateFormatData(selfRegistratinDetails);
		const encryptedRegDetails = await encryptData(
			dateFormattedRegDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		const body = {
			selfRegistrationDetails: encryptedRegDetails,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving self registration details: ${e}`);
	}
};

export const saveInsuranceDetails = async (vehiclePurchasedId, insuranceDetails) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/saveInsuranceDetails`;
		const dateFormattedInsuranceDetails = dateFormatData(insuranceDetails);
		const body = {
			insuranceDetails: dateFormattedInsuranceDetails,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving basic insurance data: ${e}`);
	}
};

// function which saves the registration file upload details to the database
export const saveRegistrationFileUploads = async (
	vehiclePurchasedId,
	registrationFileUploadKey,
	registrationFileUploadValue,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/registrationFileUploads`;
		const body = {
			registrationFileUploadKey,
			registrationFileUploadValue,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving registration file: ${e}`);
	}
};

// function which saves the stipulation file upload or written response details to the database
export const saveStipulationEvidence = async (
	vehiclePurchasedId,
	ekhoStipKey,
	fileUploadValues = null,
	writtenResponsesValues = null,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/saveStipulationEvidence`;
		const body = {
			ekhoStipKey,
			files: fileUploadValues,
			writtenResponses: writtenResponsesValues,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving stipulation evidence file: ${e}`);
	}
};

export const saveCreditApplication = async (
	vehiclePurchasedId,
	creditApplication,
	submitApp = true,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/creditApplication`;
		// Encrypt the applicant credit application data
		const dateFormattedApplicationDetails = dateFormatData(creditApplication.applicantDetails);
		const encryptedApplicantDetails = await encryptData(
			dateFormattedApplicationDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		const dateFormattedEmploymentDetails = dateFormatData(creditApplication.employmentDetails);
		const encryptedEmploymentDetails = await encryptData(
			dateFormattedEmploymentDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		creditApplication.applicantDetails = encryptedApplicantDetails;
		creditApplication.employmentDetails = encryptedEmploymentDetails;
		const body = { creditApplication, oemKey: process.env.REACT_APP_OEM_KEY, submitApp };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving credit application: ${e}`);
	}
};

export const saveCurrentPage = async (
	placedOrderId,
	purchasedVehicleId,
	pageData,
	sectionKey,
	newActiveSectionKey = null,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2/currentPage`;
		const body = {
			sectionKey,
			pageData,
			oemKey: process.env.REACT_APP_OEM_KEY,
			purchasedVehicleId,
			placedOrderId,
			newActiveSectionKey,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving current page: ${e}`);
	}
};

export const saveActiveSection = async (placedOrderId, activeSection, purchasedVehicleId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2/activeSection`;
		const body = {
			sectionKey: activeSection,
			oemKey: process.env.REACT_APP_OEM_KEY,
			placedOrderId,
			purchasedVehicleId,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving active section: ${e}`);
	}
};

export const savePickUpLocation = async (vehiclePurchasedId, pickUpData) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/pickUpLocation`;
		const body = { pickUpData, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving pickup location id: ${e}`);
	}
};

export const saveDeliveryLocation = async (vehiclePurchasedId, deliveryData) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/deliveryLocation`;
		const body = { deliveryData, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving pickup location id: ${e}`);
	}
};

export const saveOtherLenderInfo = async (vehiclePurchasedId, otherLenderData) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/otherLenderInfo`;
		const dateFormattedOtherLenderData = dateFormatData(otherLenderData);
		const body = {
			otherLenderData: dateFormattedOtherLenderData,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving registration data: ${e}`);
	}
};

export const signOffOnVehicle = async (vehiclePurchasedId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/signOff`;
		const body = { oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error signing off on vehicle: ${e}`);
	}
};

export const fetchStateObject = async (state) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/state_onfetch_v2/state/${state}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching state object: ${e}`);
	}
};

export const saveNativeFinancingBuyerPreference = async (
	purchasedVehicleId,
	nativeFinancingBuyersDecision,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const roundedDecision = nativeFinancingBuyersDecision;
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/nativeFinancingBuyerPreference`;
		const appCheckToken = await getAppCheckToken();
		const body = {
			nativeFinancingBuyersDecision: roundedDecision,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving native financing choice id: ${e}`);
	}
};

export const saveNativeFinancingBuyerOfferDecision = async (
	vehiclePurchasedId,
	buyerOfferDecision,
	additionalDuesId,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/nativeFinancingBuyerOfferDecision`;
		const appCheckToken = await getAppCheckToken();
		const body = {
			buyerOfferDecision,
			additionalDuesId,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving native financing buyer offer decision: ${e}`);
	}
};

export const fetchPaymentIntentAmount = async (currentVehicle, paymentType) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/fetchPaymentIntentAmount`;
		const paymentIntentId =
			paymentType === PAYMENT_TYPES.LOAN_ADDITIONAL_DOWN
				? currentVehicle.paymentInfo.nativeFinancing.downPayment.id
				: currentVehicle.paymentInfo.cashPayment.id;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, paymentIntentId },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching payment intent amount: ${e}`);
	}
};

export const createVerificationSession = async (orderId, registrationState, tarAgent) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/identity_oncreate_v2/createVerificationSession`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			brandKey: process.env.REACT_APP_BRAND_KEY,
			orderId,
			registrationState,
			tarAgent,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating identity verification session: ${e}`);
	}
};

export const cancelVerificationSession = async (orderId, registrationState, sessionId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/identity_oncreate_v2/cancelVerificationSession/${sessionId}`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			brandKey: process.env.REACT_APP_BRAND_KEY,
			orderId,
			registrationState,
			sessionId,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error cancelling identity verification session: ${e}`);
	}
};

export const validateVerificationSession = async (orderId, registrationState, sessionId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/identity_oncreate_v2/validateVerificationSession/${sessionId}`;
		const body = { oemKey: process.env.REACT_APP_OEM_KEY, orderId, registrationState };
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error validating identity verification session: ${e}`);
	}
};

export const getDocumentSigningLink = async (
	placedOrderId,
	purchasedVehicleId,
	pageName,
	dealerId,
) => {
	// If IS_DEMO exists in the environment, return a demo link
	if (process.env.REACT_APP_IS_DEMO && DEMO_DOCUMENT_SIGNING_LINK_MAP[pageName]) {
		// wait 300ms
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});
		return { documentSetViewingId: DEMO_DOCUMENT_SIGNING_LINK_MAP[pageName] };
	}
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/esigs_onfetch_v2/fetchDocumentSigningLink`;
		const appCheckToken = await getAppCheckToken();
		const body = {
			placedOrderId,
			purchasedVehicleId,
			pageName,
			dealerId,
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		if (e.response && e.response.data) {
			const errorResponse = `Error fetching document signing link: ${JSON.stringify(
				e.response.data,
			)}`;
			createLog('Object-fetch-failed', errorResponse, 'ERROR', {});
			throw new Error(errorResponse);
		}
		throw new Error(`Error fetching document signing link: ${e}`);
	}
};

// INSURANCE REQUESTS

export const buyerChoseToRecheckInsurance = async (oemKey, placedOrderId, purchasedVehicleId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/insurance_oncreate_v2/buyerChoseToRecheckInsurance`;
		const appCheckToken = await getAppCheckToken();
		const body = {
			oemKey,
			placedOrderId,
			purchasedVehicleId,
		};
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response;
	} catch (e) {
		throw new Error(`Error rechecking user's insurance: ${e}`);
	}
};

export const fetchStateInsuranceRequirements = async (
	registrationState,
	buyerFullName,
	oemName,
	oemId,
	oemKey,
	placedOrderId,
	purchasedVehicleId,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/insurance_onfetch_v2/fetchStateInsuranceRequirements`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: {
				registrationState,
				buyerFullName,
				oemName,
				oemId,
				oemKey,
				placedOrderId,
				purchasedVehicleId,
			},
		});
		return response;
	} catch (e) {
		throw new Error(`Error fetching insurance requirements: ${e}`);
	}
};

// for testing
// export const downloadBuyerForms = async (purchasedVehicleId) => {
// 	const accessToken =
// 		'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEtM0NVbEgyQ2J4Z2pUSVBDcTE0MiJ9.eyJodHRwczovL3ZpdHUuY29tL2FjY291bnQvdXNlci1pZCI6IjYwNjgwIiwiaXNzIjoiaHR0cHM6Ly92aXR1LXN0YWdlLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJidXYyV0xEVlhvclpOTzl1TUNKQWRnMEg5VlFUM2RmSUBjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly92aXR1LmNvbS9uYXRpb25hbC1wdWJsaWMtYXBpIiwiaWF0IjoxNjc2OTIxMDEyLCJleHAiOjE2NzcxODAyMTIsImF6cCI6ImJ1djJXTERWWG9yWk5POXVNQ0pBZGcwSDlWUVQzZGZJIiwic2NvcGUiOiJhcGlfYWNjZXNzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIn0.e6aazRog43_xqr3ampFHJwlXksv1tjgqBq5Yj_3svq72RS_ixrDyGTyEICWNFH6xxX3giYGmRfn96OzqRCPbPdsp9LDj73YsyRbDhiRmXiMrqUdOyvkNd3lTji-Sd4_kIzf8CWGxTD6h7BS8IqQUKYfJlebmBpeJpuGyCLbkC2JuXUxpfQ6XnauO2LgamY2zDRRwwTvk6pKoEw4KYocU5uatiDhtZ0YvZCFcZsQEirRtT_l6DwntgOPSmsSpXsdjkqMkNYzyFz9AlE4h1A--IoaQ21sEyvT0E8WKf7DjqCgicDKsWRp5K9UgCo6xEoHXszbiC692k1MT2fZPAOPtcg';
// 	const tid = 746354;
// 	const formIds = [1252918, 1252919, 1252920];
// 	// Make a call to the backend server passing the purchaseVehicleId and getting back the mergedPDFBytes for the Tar forms

// 	// Merge necessary user docs into one pdf
// 	const pdfDoc = await PDFDocument.create();
// 	await Promise.all(
// 		formIds.map(async (formId) => {
// 			try {
// 				const url = `https://proxy-stage-developer.vitu.com/national-public-api/v1/transaction/${tid}/forms/download/${formId}`;
// 				const doc = await axios.get(url, {
// 					headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/pdf' },
// 					responseType: 'arraybuffer',
// 				});
// 				const pdf = await PDFDocument.load(doc.data);
// 				const [pdfPage] = await pdfDoc.copyPages(pdf, [0]);
// 				pdfDoc.addPage(pdfPage);
// 			} catch (e) {
// 				console.error(`Error downloading TAR form ${formId}: ${e}`);
// 			}
// 		}),
// 	);
// 	const mergedPdfBytes = await pdfDoc.save();

// 	// download merged pdf
// 	const fileToDowload = new Blob([mergedPdfBytes], { type: 'application/pdf' });
// 	saveAs(fileToDowload, 'VehicleRegistrationPacket.pdf');
// };

// Function which downloads the necessary forms a buyer needs to print and sign
// Parameters: purchasedVehicleId, and optionally transactionId (ideal) => otherwise fetched from purchasedVehicleId
export const downloadBuyerForms = async (purchasedVehicleId, transactionId = null) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/tar_onfetch_v2/getBuyerForms`;
		const appCheckToken = await getAppCheckToken();
		const requestHeaders = getRequestHeaders(appCheckToken, token);
		requestHeaders.accept = 'application/pdf';
		const response = await axios.get(url, {
			headers: requestHeaders,
			params: { oemKey: process.env.REACT_APP_OEM_KEY, transactionId, purchasedVehicleId },
			responseType: 'blob',
		});
		const fileToDowload = new Blob([response.data], { type: 'application/pdf' });
		saveAs(fileToDowload, 'VehicleRegistrationPacket.pdf');
	} catch (e) {
		// console.error(`Error downloading TAR forms: ${e}`);
		throw new Error(`Error downloading TAR forms: ${e}`);
	}
};

// Function which creates a registration transaction in the backend
export const genRegTransaction = async (data) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/tar_oncreate_v2/createTransaction`;
		const { placedOrderId, purchasedVehicleId } = data;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			placedOrderId,
			purchasedVehicleId,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		if (response.status === 200) {
			return {};
		}
		throw new Error(`Error generating registration transaction: ${response.error}`);
	} catch (e) {
		throw new Error(`Error generating registration transaction: ${e}`);
	}
};

export async function genRegFees(
	placedOrderId,
	purchasedVehicleId,
	purchasedVehicleProductId,
	oemId,
	registrationState,
) {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/tar_oncreate_v2/createFeeEstimate`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			placedOrderId,
			purchasedVehicleId,
			additionalFieldsMap: ADDITIONAL_FIELDS_MAP,
			purchasedVehicleProductId,
			oemId,
			registrationState,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		if (response.status === 200) {
			return {};
		}
		throw new Error(`Error generating fees: ${response.error}`);
	} catch (e) {
		throw new Error(`Error generating fees: ${e}`);
	}
}

export async function updateVehicleFulfillmentStatus(
	purchasedVehicleId,
	vehicleFulfillmentStatus,
	cancelationAgent = null,
	fulfillmentDetails = null,
	estimatedDeliveryDate = null,
) {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/vehicleFulfillmentStatus`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			vehicleFulfillmentStatus,
			cancelationAgent,
			fulfillmentDetails,
			estimatedDeliveryDate,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		if (response.status === 200) {
			return {};
		}
		throw new Error(`Error generating fees: ${response.error}`);
	} catch (e) {
		throw new Error(`Error generating fees: ${e}`);
	}
}

// export const createFullVehiclePaymentIntent = async (
// 	purchasedVehicle,
// 	processingFeeInc = false,
// ) => {
// 	// Create Stripe payment intent
// 	const body = {
// 		oemKey: process.env.REACT_APP_OEM_KEY,
// 		purchasedVehicle,
// 		processingFeeInc,
// 	};
// 	try {
// 		const token = await auth.currentUser.getIdToken();
// 		const url = `${FUNCTION_HOST}/stripePayment_v2/createFullVehiclePaymentIntent`;
// 		const appCheckToken = await getAppCheckToken();
// 		const response = await axios.post(url, body, {
// 			headers: getRequestHeaders(appCheckToken, token),
// 		});
// 		return response.data;
// 	} catch (e) {
// 		throw new Error(`Error creating payment intent associated with a placed order: ${e}`);
// 	}
// };

export const createBuyerPortalPaymentIntent = async (
	purchasedVehicle,
	paymentDescription,
	paymentType,
	paymentMethods,
	processingFeeInc = false,
) => {
	// Create Stripe payment intent
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		brandKey: process.env.REACT_APP_BRAND_KEY,
		paymentMethodTypes: paymentMethods,
		paymentDescription,
		paymentType,
		processingFeeInc,
		purchasedVehicleId: purchasedVehicle.id,
		oemId: purchasedVehicle.oemId,
	};

	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/createBuyerPortalPaymentIntent`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating payment intent associated with a placed order: ${e}`);
	}
};

export const createDownpaymentPaymentIntent = async (
	purchasedVehicle,
	alreadyPaid,
	processingFeeInc = false,
) => {
	// Create Stripe payment intent
	// NOTE: To calculate the amount of the additional down payment, we need to subtract the order fee (and any additional payments) from the lender decision down payment
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		purchasedVehicleId: purchasedVehicle.id,
		placedOrderId: purchasedVehicle.placedOrderId,
		processingFeeInc,
		downPayment:
			purchasedVehicle.paymentInfo.nativeFinancing.lenderDecision.downPayment - alreadyPaid,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/createDownpaymentPaymentIntent`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating payment intent associated with a placed order: ${e}`);
	}
};

export const addRollUpFieldsToVehicle = async (vehicleId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_onfetch_v2/${vehicleId}/addRollUpFields`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error adding roll up fields to vehicle: ${e}`);
	}
};

// export const updatePaymentIntentAmountMultiplier = async (
// 	paymentIntentId,
// 	dbObj,
// 	dbObjId,
// 	multiplier,
// 	paymentDataPath,
// 	processingFeeInc,
// ) => {
// 	const body = {
// 		oemKey: process.env.REACT_APP_OEM_KEY,
// 		paymentIntentId,
// 		dbObj,
// 		dbObjId,
// 		multiplier,
// 		paymentDataPath,
// 		processingFeeInc,
// 	};
// 	try {
// 		const token = await auth.currentUser.getIdToken();
// 		const url = `${FUNCTION_HOST}/stripePayment_v2/updatePaymentIntentAmount`;
// 		const appCheckToken = await getAppCheckToken();
// 		await axios.post(url, body, {
// 			headers: getRequestHeaders(appCheckToken, token),
// 		});
// 	} catch (e) {
// 		throw new Error(`Error updating payment intent amount: ${e}`);
// 	}
// };

export const updatePaymentIntentAmountFixed = async (
	paymentIntentId,
	dbObj,
	dbObjIds,
	amount,
	paymentDataPath,
	processingFeeInc,
	processingFeeIncAmount,
) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		paymentIntentId,
		dbObj,
		dbObjIds,
		amount,
		paymentDataPath,
		processingFeeInc,
		processingFeeIncAmount,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/updatePaymentIntentAmount`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error updating payment intent amount: ${e}`);
	}
};

export const saveGenericData = async (purchasedVehicleId, dataKey, dataValue) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		dataKey,
		dataValue,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/saveGenericData`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving choice data: ${e}`);
	}
};

export const fetchCurrentPageMapAndVerificationStatus = async (
	placedOrderId,
	purchasedVehicleId,
) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_onfetch_v2/currentPageMapAndVerificationStatus`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, placedOrderId, purchasedVehicleId },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching authenticated oem data: ${e}`);
	}
};

// FUNCTION PURELY FOR TESTING
export const makeTestCreditDecision = async (purchasedVehicleId, lenderDecision) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		purchasedVehicleId,
		lenderDecision,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/test_events_v2/makeCreditDecision`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving choice data: ${e}`);
	}
};

export const handleLenderDecision = async (
	purchasedVehicleId,
	lenderDecision,
	stipulationKeys = [],
) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		lenderDecision,
		stipulationKeys,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/handleLenderDecision`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving choice data: ${e}`);
	}
};

export const buyerActive = async (buyerId) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		buyerId,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_oncreate_v2/buyerActive`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error updating buyer active: ${e}`);
	}
};

export const completeAccountCreation = async (uid) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		uid,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_oncreate_v2/completeAccountCreation`;
		const appCheckToken = await getAppCheckToken();
		const res = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		if (res.status !== 200) {
			throw new Error(`Account creation function failed.`);
		}
	} catch (e) {
		throw new Error(`Error finalizing account creation: ${e}`);
	}
};

export const checkBuyerWithEmailExists = async (email) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		email,
	};
	try {
		const url = `${FUNCTION_HOST}/buyer_onfetch_v2/checkBuyerWithEmailExists`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.data) {
			return response.data;
		}
		throw new Error(response);
	} catch (e) {
		throw new Error(`Error checking if buyer object with email exists: ${e}`);
	}
};

export const enrollInMFA = async (uid) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		uid,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_oncreate_v2/enrollInMFA`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error enrolling in mfa: ${e}`);
	}
};

export const fetchGoogleMapsApiKey = async () => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/secrets_onfetch_v2/fetchGoogleMapsApiKey`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching google maps API key: ${e}`);
	}
};

export async function updateDocumentSteps(purchasedVehicleId, documentSteps) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		documentSteps,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/documentSteps`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error updating document steps: ${e}`);
	}
}

//   - auth: firebase auth object
//   - logName: string containing log name. Naming convention: separate words with hyphens
//   - logMessage: string containing log message
//   - severity: severity of the log -> enum: INFO, WARNING, ERROR, CRITICAL
//   - labels: object containing custom labels -> userId, oemKey, etc)
// NOTE: always call this function without await. We don't want to block on logging.
export async function createLog(logName, logMessage, severity, labels) {
	//   - projectId: string containing projectId
	const projectId = OEM_KEY_TO_PROJECT_MAP[process.env.REACT_APP_OEM_KEY];
	if (!projectId) {
		throw new Error(`Invalid environment: ${process.env.REACT_APP_OEM_KEY}`);
	}
	//   - siteName: string containing client app site name -> // i.e.) 'e2etestoem' (for buyer portal) || 'e2etestoem-checkout' (for checkout)
	const siteName = window.location.hostname.replace('.ekhodealer.com', '');
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		logName,
		logMessage,
		siteName,
		projectId,
		severity,
		labels: { ...labels, oemKey: process.env.REACT_APP_OEM_KEY },
	};
	try {
		const appCheckToken = await getAppCheckToken();
		// if calling from an authenticated environment
		if (auth && auth.currentUser) {
			const token = await auth.currentUser.getIdToken();
			const url = `${FUNCTION_HOST}/log_oncreate_v2`;
			await axios.post(url, body, {
				headers: getRequestHeaders(appCheckToken, token),
			});
		} else {
			const url = `${FUNCTION_HOST}/log_oncreate_v2/unauthenticated`;
			await axios.post(url, body, {
				headers: getRequestHeaders(appCheckToken),
			});
		}
	} catch (e) {
		throw new Error(`Error creating log: ${e}`);
	}
}

export const fetchPaymentIntent = async (paymentIntentId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/fetchPaymentIntent`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, paymentIntentId },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching payment intent: ${e}`);
	}
};

export async function decrypt(data) {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/decrypt_v2/decrypt`;
		const body = {
			encryptedData: data,
			oemKey: process.env.REACT_APP_OEM_KEY,
			buyerId: auth.currentUser.uid,
		};
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error decrypting data: ${e}`);
	}
}

export async function updateFinancingActionItem(
	purchasedVehicleId,
	updatedActionItem,
	daysUntilSubmission = null,
) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		updatedActionItem,
		daysUntilSubmission,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/financingActionItem`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error updated financing action item: ${e}`);
	}
}

// Function for performing a relocation on a purchased vehicle (i.e. moving it from one location to another)
// Actions: adds a new relocation object to purchased vehicle, updates active section + page, and (optionally) ackwnowledges the source modal
export async function performRelocation(
	purchasedVehicleId,
	relocationName,
	relocPosition,
	sourceModalId,
	metadata,
) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		relocationName,
		relocPosition,
		sourceModalId,
		metadata,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/relocation`;
		const appCheckToken = await getAppCheckToken();

		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating relocation: ${e}`);
	}
}

export async function createModal(purchasedVehicleId, modalName, modalPosition) {
	try {
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			modalName,
			modalPosition,
		};
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/modal`;
		const appCheckToken = await getAppCheckToken();

		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating relocation: ${e}`);
	}
}

// Function for deleting alert modal from a given vehicle
export async function deleteModal(purchasedVehicleId, modalId) {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_ondelete_v2/${purchasedVehicleId}/modal`;
		const appCheckToken = await getAppCheckToken();

		const response = await axios.delete(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				modalId,
			},
		});
		return response;
	} catch (e) {
		throw new Error(`Error deleting modal: ${e}`);
	}
}

// Function for deleting relocation flow from a given vehicle
export async function deleteRelocation(purchasedVehicleId, relocId) {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_ondelete_v2/${purchasedVehicleId}/relocation`;
		const appCheckToken = await getAppCheckToken();

		const response = await axios.delete(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				relocId,
			},
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error deleting relocation: ${e}`);
	}
}

// not yet working -- TO DO
export async function downloadPandaDocDocument(documentSetId, documentSetName) {
	try {
		const token = await auth.currentUser.getIdToken(true); // Ensure to get the latest token
		const appCheckToken = await getAppCheckToken(); // Assume this function gets the AppCheck token
		const url = `${FUNCTION_HOST}/esigs_onfetch_v2/downloadPandaDocument?documentSetId=${documentSetId}&oemKey=${process.env.REACT_APP_OEM_KEY}`;

		// Axios call for GET request
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			responseType: 'blob', // Important for handling PDF data
		});

		// Create a URL for the blob
		const blob = new Blob([response.data], { type: 'application/pdf' });
		const displayUrl = window.URL.createObjectURL(blob);

		// Trigger download
		const link = document.createElement('a');
		link.href = displayUrl;
		link.setAttribute('download', `${documentSetName}.pdf`); // Set the file name for the download
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link); // Clean up after triggering download

		return displayUrl; // Return the URL for displaying the PDF in the browser
	} catch (e) {
		throw new Error(`Error in downloading and viewing PandaDoc document: ${e.message}`);
	}
}

// ONLY USED TO GET PAST DOC SIG SECTIONS IN DEMOS
export async function markDocSetSigned(pageName, purchasedVehicleId) {
	try {
		const token = await auth.currentUser.getIdToken();
		const appCheckToken = await getAppCheckToken();
		const url = `${FUNCTION_HOST}/test_events_v2/performEsigIntervention`;
		const body = {
			pageName,
			purchasedVehicleId,
			oemKey: process.env.REACT_APP_OEM_KEY,
			complete: true,
		};
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error marking document set signed: ${e}`);
	}
}

export async function sendTransactionalEmail(messageData, messageId) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		messageData,
		messageId,
	};
	try {
		const url = `${FUNCTION_HOST}/send_transactional_email_v2/sendTransactionalEmail`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.status !== 200) {
			throw new Error(`Error sending transactional email. Response: ${response}`);
		}
		return response;
	} catch (e) {
		throw new Error(`Error sending transactional email: ${e}`);
	}
}

// FUNCTIONS USED BY BUTTONS IN THE DEV PORTAL TO SIMULATE THINGS:
export async function setVin(purchasedVehicleId, vin = '1G1YY32G8W5111111') {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		vin,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/vin`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error setting vin: ${e}`);
	}
}

// DEMO ENDPOINT
export async function setOdometerReading(purchasedVehicleId, odometerReading = 10) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		odometerReading,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/odometerReading`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error setting odom reading: ${e}`);
	}
}

// DEMO ENDPOINT
export async function setOdometerValidity(purchasedVehicleId, odometerValidity = 'Actual') {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		odometerValidity,
	};
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/odometerValidity`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error setting odom validity: ${e}`);
	}
}

// DEMO ENDPOINT
export const prepDemoTarData = async (vehiclePurchasedId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/prepDemoTarData`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error prepping tar data: ${e}`);
	}
};

// DEMO ENDPOINT
export const setIdentityVerified = async (vehiclePurchasedId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/setIdentityVerified`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error setting ID verified: ${e}`);
	}
};

// DEMO ENDPOINT
export const markVehicleAsComplete = async (vehiclePurchasedId) => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/stage`;
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			stage: VEHICLE_STAGE.COMPLETE,
		};
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken, token),
		});
	} catch (e) {
		throw new Error(`Error saving basic insurance data: ${e}`);
	}
};
